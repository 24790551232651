<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">{{ $t("tagListPage.pageTitle") }}</strong>
            <CButton
              v-if="isRole"
              color="info"
              @click="onCreateClicked()"
            >{{ $t("common.create") }}</CButton>
          </CCardHeader>
          <CCardBody class="px-5">
            <CForm class="submit_form">
              <CRow>
                <CCol class="label">
                  <strong> {{$t("tagListPage.typeLabel")}}</strong>
                </CCol>
                <CCol sm="2">
                  <CSelect
                    :value.sync="selectedType"
                    :options="TAG_TYPE"
                    @change="reqGet()"
                  />
                </CCol>
              </CRow>
            </CForm>
            <table
              class="edge-type-table table table-sm table-striped table-bordered"
            >
              <thead class="bg-light text-body text-center">
                <tr>
                  <th>{{ $t("common.name") }}</th>
                  <th width="120">{{ $t("common.action") }}</th>
                </tr>
              </thead>

              <Draggable
                class="table-row-drag"
                v-model="tableItems"
                @change="onSortEnd"
                v-bind="dragOptions"
                v-if="canShowTable"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                  tag="tbody"
                >
                  <tr v-for="item in tableItems" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td class="text-center">
                      <CButton
                        v-if="isRole"
                        class=""
                        color="info"
                        square
                        size="sm"
                        @click="onEditClicked(item.id)"
                        ><i class="icon cil-pencil mr-1"></i
                        >{{ $t("common.edit") }}</CButton
                      >
                      <CButton
                        v-if="!isRole"
                        class=""
                        color="info"
                        square
                        size="sm"
                        @click="onDetailClicked(item.id)"
                        ><i class="icon cil-pencil mr-1"></i
                        >{{ $t("tagListPage.detail") }}</CButton
                      >
                    </td>
                  </tr>
                </transition-group>
              </Draggable>
            </table>
            <!-- #endregion Table -->
          </CCardBody>
          <transition name="fade">
            <CRow v-if="tagNotFound">
              <CCol>
                <div class="d-flex justify-content-center">
                  <CAlert
                    color="danger"
                    class="w-50 p-4 text-center"
                    ><h5>{{ $t("tagListPage.notFound") }}</h5></CAlert>
                </div>
              </CCol>
            </CRow>
          </transition>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
// import i18n from "../../../i18n";
// import axios from "axios";
import TAG_TYPE from "@/mixins/property";

const TAG_URL = `/rest/tag`;
const PAGE_URL = `/admin/tag`;

const ROLE = {
  ADMIN: "yado_operator_admin",
 };

export default {
  name: "TagList",
  url: TAG_URL,
  pageUrl: PAGE_URL,
  mixins: [TAG_TYPE],

  computed: {

    /** ロールの判定 */
    isRole() {
      // アカウントのロールリスト
      const roles = this.$keycloak.realmAccess.roles;
      // adminのみtrueそれ以外はfalse
      return roles.includes(ROLE.ADMIN);
    },

    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true;
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.response.length < 1;
    },

    /** drag and drop */
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },

  },
  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },

  },
  data() {
    return {
      loading: false,
      successModal: false,
      errorModal: false,
      isDisabled: true,
      tagNotFound:false,

      selectedType: 1,
      
      errorMessage: "",
      currentTags: [],

      /** 一覧データ */
      tableItems: [],

      response: [],

    };
  },
  methods: {
    /** 新規作成ボタン押下 */
    onCreateClicked() {
      this.$router.push({
        path: `${PAGE_URL}/create`,
        query: { type: this.selectedType },
      });
    },
    /** 編集ボタン押下 */
    onEditClicked(id) {
      this.$router.push({
        path: `${PAGE_URL}/${id}/edit`,
        query: { type: this.selectedType },
      });
    },

    /**詳細ボタン押下 */
    onDetailClicked(id) {
      this.$router.push({
        path: `${PAGE_URL}/${id}/edit`,
        query: { type: this.selectedType },
      });
    },

    /** 一覧表ソート(並び替え) */
    // TODO:開発中
    onSortEnd(event) {
      // NOTE:イベントが起きたら起動するための引数で処理で使用しない為ここで定義
      event;

      const order = [];
      this.tableItems.forEach((a)=>{
        order.push(a.id)
      })

      // 配列内を番号順にソート
      order.sort((a, b) => a - b);

      // リスト表示している項目に番号を採番
      this.tableItems.forEach((a, i) => {
          this.tableItems[i].displayOrder = order[i];
      });
      this.reqPutDisplayOrder();
    },

    //#region Request
    reqGet() {

      // NOTE:初期表示の時this.selectedTypeがNaN
      if(isNaN(this.selectedType)) {this.selectedType = 1;}
      this.tagNotFound = false;
      this.tableItems = [];

      const callback = (a) => {
        this.response = a.tags;
        this.pourTable(this.response);
        this.loading = false;
      };

      const errorCallback = (err) => {
        this.tagNotFound = true;
        this.errorMessage = `${err.name} : ${err.message}`;
        this.PassError = err.response.status;
        this.loading = false;
      };

      this.reqGetTagAllByType(this.selectedType,callback,errorCallback);
    },

    /** 表示順更新 */
    reqPutDisplayOrder(){
      const body = { list: [] };
      this.tableItems.forEach((a) => {
        body.list.push({ id: a.id, displayOrder: a.displayOrder });
      });

      const callback = () => this.reqGet();

      const errorCallback = (err) => {
        this.errorModal = true;
        this.errorMessage = `${err.name} : ${err.message}`;
      };

      this.reqPutAmenityOrder(body,callback,errorCallback);
    },

    //#endregion Request

    //#region Method

    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        this.tableItems.push({
          id: data.id,
          name: data.name,
          status: data.status,
          // NOTE:APIへはdisplayOrderで送る必要あり
          displayOrder: data.order ?? data.id,
        });
      });
      // console.log(this.tableItems);
    },

    /**タイプ指定されているか確認 */

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    backPage() {
      this.$router.go(-1);
    },
    
  },
  beforeMount() {
    this.selectedType = Number(this.$route.query.type);
    console.log(this.selectedType)
    this.reqGet();
  },
};
</script>